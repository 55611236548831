import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Config from './config/index'
import Http from './http/index'
import Store from './store/index'
import Storage from "store"

import { Tabbar, TabbarItem, NavBar, Icon, Swipe, SwipeItem, Col, Row, Skeleton, Tab, Tabs, Notify, Sticky, List, Lazyload, Button, Toast, Dialog, Field, Form, ActionSheet, Checkbox, CheckboxGroup, Image, Uploader, Cell, CellGroup, Popup, Picker, Area, Calendar, RadioGroup, Radio, NoticeBar, Empty } from 'vant'

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Skeleton)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Notify)
Vue.use(Sticky)
Vue.use(List)
Vue.use(Lazyload, {
  lazyComponent: true,
})
Vue.use(Button)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(Form)
Vue.use(ActionSheet)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Image)
Vue.use(Uploader)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Area)
Vue.use(Calendar)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(NoticeBar)
Vue.use(Empty)

Vue.config.productionTip = false
Vue.prototype.$http = Http
Vue.prototype.$store = Store
Vue.prototype.$storage = Storage
Vue.prototype.$config = Config

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
