import axios from "axios"
import store from "store"

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '//ydh.haiyiban.com/api/'
} else {
    axios.defaults.baseURL = 'https://ydh.haiyiban.com/api/'
}

// 发送请求之前做特殊处理
axios.interceptors.request.use(
    config => {
        let userinfo = store.get('userinfo')
        if(userinfo){
            config.headers['Token'] = userinfo.token
        }
        config.headers['Device'] = 'mobile'
        return config
    }
)

// 返回结果前做特殊处理
axios.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        if(error.message=='Request failed with status code 401'&&error.response.data.msg=='请登录后操作'){
            store.remove('userinfo')
            window.location.reload()
        }
        return Promise.reject(error)
    }
)

const get = (url, data) => {
    return axios({
        method: 'get',
        url: url,
        data: data,
    })
}

const post = (url, data) => {
    return axios({
        method: 'post',
        url: url,
        data: data,
    })
}

const ajax = {
    get: get,
    post: post,
}

export default ajax